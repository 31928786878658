.app-calendar {
  @apply border border-app-grey-2 bg-app-light-grey-3 rounded-md px-4 py-2 sm:p-4 text-center space-y-4 text-app-grey-3 text-xs sm:text-sm;
}
.app-calendar .react-calendar__navigation {
  @apply flex gap-2 sm:gap-4 sm:px-6 items-center;
}
.app-calendar .react-calendar__navigation__label {
  @apply font-semibold text-sm sm:text-lg;
}
.app-calendar .react-calendar__navigation__arrow {
  @apply p-2 text-xl sm:text-3xl;
}
.app-calendar .react-calendar__navigation__arrow:disabled {
  @apply p-2 text-xl sm:text-3xl text-app-grey-2;
}
.app-calendar .react-calendar__month-view__weekdays {
  @apply pb-3 no-underline;
}
.app-calendar .react-calendar__month-view__weekdays abbr:where([title]) {
  @apply no-underline font-medium;
}
.app-calendar .react-calendar__tile {
  @apply my-3 w-6 h-6;
}
.app-calendar .react-calendar__tile:disabled {
  @apply my-3 w-6 h-6 text-app-grey-2;
}
.app-calendar .react-calendar__tile--now {
  @apply flex items-center justify-center;
}
.app-calendar .react-calendar__tile--now abbr {
  @apply text-app-blue sm:w-6 sm:h-6 rounded-full flex items-center justify-center;
}
.app-calendar .react-calendar__tile--active {
  @apply flex items-center justify-center;
}
.app-calendar .react-calendar__tile--active abbr {
  @apply bg-black text-white w-5 h-5 sm:w-6 sm:h-6 rounded-full flex items-center justify-center;
}
.app-calendar .react-calendar__month-view__days__day--weekend {
  @apply text-app-brown;
}
